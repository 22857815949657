import * as React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  video: {
    marginTop: theme.spacing(2),
  },
  iframeContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    height: 0,
  },
}));

function Treatment(props) {
  const { treatment = [], visitId = '' } = props;
  const classes = useStyles();
  const treatmentId = treatment.id;
  return (
    <>
      <br />
      <Typography variant="subtitle1" fontWeight={700} display="block" gutterBottom className={classes.overline}>
        {treatment.name}
      </Typography>
      {treatment &&
        treatment.videos.map((video, index) => (
          <React.Fragment key={`treatment-${treatmentId}-${visitId}-${index}`}>
            <Typography variant="subtitle2" fontWeight={700} display="block" gutterBottom className={classes.overline}>
              Level {index + 1}
            </Typography>
            {
              <div className={classes.iframeContainer}>
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  width="560"
                  height="315"
                  src={'https://www.youtube.com/embed/' + new URL(video.url).searchParams.get('v')}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            }
            <br />
          </React.Fragment>
        ))}
    </>
  );
}

export default Treatment;
