import React from 'react';
import { CircularProgress } from '@mui/material';

export const CircularProgressBar = updating => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {updating && <CircularProgress color="primary" />}
    </div>
  );
};
