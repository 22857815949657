import * as React from 'react';
import Typography from '@mui/material/Typography';
import Treatment from './Treatment';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  overline: {
    margin: theme.spacing(1, 0, 2),
    fontSize: '15pt',
    color: '#6a7685',
  },
}));

function CarePlan(props) {
  const classes = useStyles();
  const { plan = '', createdAt = '', treatments = [], visitId = '', index = 0 } = props;
  const date = new Date(createdAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const [isExpanded, setIsExpanded] = useState(index === 0);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <React.Fragment>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{formattedDate}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant={'body1'} paragraph style={{ whiteSpace: 'pre-line' }}>
            {plan}
          </Typography>
          {treatments &&
            treatments.map(treatment => {
              let key = `treatment-${visitId}-${treatment.id}-${index}`;
              return (
                <React.Fragment key={key}>
                  <Treatment treatment={treatment} visitId={visitId} />
                </React.Fragment>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default CarePlan;
