import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { gql, useQuery } from '@apollo/client';

import ButtonAppBar from './NavBar';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  instructions: {
    margin: theme.spacing(0, 0, 2),
    fontSize: '15pt',
    color: '#6a7685',
  },
  visitMode: {
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#41c617',
    },
  },
  visitSelect: {
    width: '100%',
    maxWidth: 400,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    '& .MuiSelect-select': {
      padding: theme.spacing(1),
    },
  },
  optionPrimary: {
    fontSize: '1rem',
    display: 'block',
  },
  optionSecondary: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
    display: 'block',
  },
  visitHeading: {
    fontSize: '1.2rem',
    fontWeight: '500',
    margin: theme.spacing(-1, 0, 3),
    borderBottom: 0,
    textAlign: 'center',
  },
  switchCalendars: {
    margin: theme.spacing(-2, 0, 4),
    textAlign: 'center',
    fontSize: '11pt',
    color: '#6a7685',
  },
}));

const ME_WITH_GROUP = gql`
  {
    me {
      isEnrolled
      firstname
      lastname
      email
      state
      language
      group {
        id
        name
      }
    }
  }
`;

// TODO: future improvement to remove the env var and hardcoded urls
// and productize support for in-person visits directly into the model
const SUMNER_GROUP_ID = process.env.REACT_APP_SUMNER_GROUP_ID;
const SUMNER_IN_PERSON_ELLIS_CALENDLY_URL = 'https://calendly.com/sumner-in-person-ellis/orthopedic-evaluation';
const SUMNER_IN_PERSON_PORTLAND_CALENDLY_URL = 'https://calendly.com/sumner-in-person-portland/orthopedic-evaluation';

// Function to check if the event is from Calendly
function isCalendlyEvent(e) {
  return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
}

function Calendly() {
  const classes = useStyles();
  const { id: episodeId } = useParams();

  // NOTE: Probably a better way to do all this mess. But it works for now.
  // The original calendly group that Heidi deleted: https://calendly.com/d/chg-xsz-nz5/orthopedic-evaluation
  const location = useLocation();
  const [hasScheduledAppointment, setHasScheduledAppointment] = useState(false);
  const [calendarURL, setCalendarURL] = useState('');
  const [urlPostFix, setUrlPostFix] = useState('');
  const [isSumnerGroup, setIsSumnerGroup] = useState(false); // if current user is from sumner group
  const [isRescheduleURL, setIsRescheduleURL] = useState(false); // if the scheduleURL is a reschedule url
  const [showVisitLocationsDropdown, setShowVisitLocationsDropdown] = useState(true); // show in-person options (hides after a slot is booked)
  const [visitMode, setVisitMode] = useState(''); // only used if isSumnerGroup is true
  const [showSumnerAppointmentSet, setShowSumnerAppointmentSet] = useState(false); // show an "appointment set" message after a slot is booked

  const appointmentType = location?.state?.appointmentType;
  const evalDescription =
    'Find a time that is convenient for you and book your initial evaluation with an Aware Health Orthopedic Clinical Specialist.';
  const followupDescription = 'Find a time that is convenient for you and book a follow-up with your Aware Health clinician.';
  const appointmentDescription = appointmentType === 'Evaluation' ? evalDescription : followupDescription;

  // TODO: remove this graphql query in the future and instead use userData via a shared context
  const { data: userData, loading: userLoading } = useQuery(ME_WITH_GROUP);

  useEffect(() => {
    if (userData?.me) {
      const urlPostFix = `?utm_source=${episodeId}&hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1&name=${encodeURIComponent(
        `${userData.me.firstname} ${userData.me.lastname}`
      )}&email=${encodeURIComponent(userData.me.email)}`;
      setUrlPostFix(urlPostFix);
      if (userData.me.group.id === SUMNER_GROUP_ID) {
        // do NOT set calendarURL here, as for Sumner patients we do not want the calendar
        // to load automatically until the patients have explicitly chosen their visit type
        setIsSumnerGroup(true);

        // for sumner users, if the url is a reschedule url, then save the status to isRescheduleURL
        // and immediately load the calendar as we will NOT be showing the in-person options
        if (location?.state?.scheduleURL.includes('/reschedulings/')) {
          setIsRescheduleURL(true);
          setCalendarURL(location?.state?.scheduleURL);
          setShowSumnerAppointmentSet(true); // true because we do have an appointment
        }
      } else {
        // for non-Sumner patients, set the calendarURL immediately so the calendar is loaded
        setCalendarURL(location?.state?.scheduleURL);
      }
    }
  }, [userData, episodeId, location.state.scheduleURL]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    if (!calendarURL) return;

    // Load script only if not already loaded
    let script;
    if (!window.Calendly) {
      script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      document.head.appendChild(script);
    }

    // Initialize widget once script is loaded
    const initWidget = () => {
      const widget = document.querySelector('.calendly-inline-widget');
      if (widget && window.Calendly) {
        widget.innerHTML = '';
        window.Calendly.initInlineWidget({
          url: calendarURL,
          parentElement: widget,
          prefill: {},
          utm: {},
        });
      }
    };

    // If Calendly is already loaded, init immediately
    if (window.Calendly) {
      initWidget();
    } else {
      // Otherwise wait for script to load
      script.onload = initWidget;
    }

    const handleMessage = e => {
      if (isCalendlyEvent(e)) {
        // console.log("Event name:", e.data.event); // Log the event name
        // console.log("Event details:", e.data.payload);    // Log the event details

        // once the user has scheduled an appointment, hide the in-person options
        // as choosing a different visit type could select a different calendar
        if (e.data.event === 'calendly.event_scheduled') {
          setHasScheduledAppointment(true);
          setShowVisitLocationsDropdown(false); // hide in-person options after slot booked
          setShowSumnerAppointmentSet(true); // show the "appointment set" message
        }
      }
    };
    window.addEventListener('message', handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [calendarURL]);

  // scroll to top of page after the appointment is set
  useEffect(() => {
    if (showSumnerAppointmentSet) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [showSumnerAppointmentSet]);

  const handleSumnerVisitModeChange = event => {
    const visitMode = event.target.value;
    setVisitMode(visitMode);

    let newUrl;
    switch (visitMode) {
      case 'in-person-ellis':
        newUrl = `${SUMNER_IN_PERSON_ELLIS_CALENDLY_URL}${urlPostFix}`;
        break;
      case 'in-person-portland':
        newUrl = `${SUMNER_IN_PERSON_PORTLAND_CALENDLY_URL}${urlPostFix}`;
        break;
      default: // virtual
        newUrl = location.state.scheduleURL;
        break;
    }
    console.log(`setting calendly url to ${newUrl}`);
    setCalendarURL(newUrl);
  };

  if (userLoading) return <p>Loading...</p>;

  return (
    <>
      {/* {console.log("before calling buttonappbar, hasscheduledappointment = " + hasScheduledAppointment)} */}
      <ButtonAppBar shouldPollNextAppointment={hasScheduledAppointment} />
      <Container component="main" maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <h1>Schedule {appointmentType}</h1>
            <div className={classes.instructions}>{appointmentDescription}</div>

            {/* for sumner users, show the in-person options only if the url is not a reschedule url */}
            {isSumnerGroup && showVisitLocationsDropdown && !isRescheduleURL && (
              <FormControl className={classes.visitSelect}>
                <Select onChange={handleSumnerVisitModeChange} value={visitMode} displayEmpty disableUnderline>
                  <MenuItem value="">
                    <span className={classes.optionPrimary}>--- Choose your visit location ---</span>
                  </MenuItem>
                  <MenuItem value="virtual">
                    <span className={classes.optionPrimary}>Virtual visit over Zoom Video Conferencing</span>
                  </MenuItem>
                  <MenuItem value="in-person-ellis">
                    <div className="flex flex-row">
                      <span className={classes.optionPrimary}>In-person visit at Ellis One to One Health Clinic</span>
                      <span className={classes.optionSecondary}>102 Indian Lake Rd, Hendersonville, TN 37075</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="in-person-portland">
                    <div className="flex flex-row">
                      <span className={classes.optionPrimary}>In-person visit at Portland One to One Health Clinic</span>
                      <span className={classes.optionSecondary}>602 South Broadway, Suite B, Portland, TN 37148</span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Grid>
          {calendarURL && (
            <Grid item xs={12} sm={12}>
              {isSumnerGroup && showSumnerAppointmentSet && (
                <div className="flex flex-row">
                  <h2 className={classes.visitHeading}>
                    {visitMode === 'virtual'
                      ? 'Virtual visit over Zoom Video Conferencing'
                      : visitMode === 'in-person-ellis'
                      ? 'In-person visit at Ellis One to One Health Clinic'
                      : 'In-person visit at Portland One to One Health Clinic'}
                  </h2>
                  {isRescheduleURL && (
                    <p className={classes.switchCalendars}>
                      Use the calendar to reschedule your appointment for the same location. To change locations, go back to cancel this
                      booking and schedule a new appointment.
                    </p>
                  )}
                </div>
              )}
              <div style={{ height: '130vh', width: '100%' }}>
                <div className="calendly-inline-widget" data-url={calendarURL} style={{ height: '100%', width: '100%' }} />
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default Calendly;
