import React from 'react';
import TextField from '@material-ui/core/TextField';
export const FormTextFieldComponent = (value, fieldName, label, error, register, validation) => {
  return (
    <TextField
      style={{ width: '100%' }}
      name={fieldName}
      label={label}
      value={value}
      variant="outlined"
      {...register(fieldName, validation)}
      error={!!error}
      helperText={error && error.message}
    />
  );
};
