import { genders, months, states } from '../../constants/Constants';
import MenuItem from '@mui/material/MenuItem';

export const stateMenuItems = getStateMenuItems();
export const monthMenuItems = getMonthMenuItems();
export const genderMenuItems = getGenderMenuItems();
export const languageMenuItems = getLanguageMenuItems();
function getStateMenuItems() {
  let stateOptionsItems = [];
  for (const [abbrev, name] of states) {
    stateOptionsItems.push(
      <MenuItem key={abbrev} value={abbrev}>
        {name}
      </MenuItem>
    );
  }
  return stateOptionsItems;
}

function getMonthMenuItems() {
  let monthItems = [];
  for (const [value, name] of months) {
    monthItems.push(
      <MenuItem key={value} value={value}>
        {name}
      </MenuItem>
    );
  }
  return monthItems;
}

function getGenderMenuItems() {
  let genderItems = [];
  for (const [value, name] of genders) {
    genderItems.push(
      <MenuItem key={value} value={value}>
        {name}
      </MenuItem>
    );
  }
  return genderItems;
}

function getLanguageMenuItems() {
  let languageItems = [];
  languageItems.push(
    <MenuItem key={'en'} value="en">
      English
    </MenuItem>
  );
  languageItems.push(
    <MenuItem key={'en'} value="es">
      Spanish
    </MenuItem>
  );
  return languageItems;
}
