import { gql, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom';
import { defaultBlue, defaultGreen, useAppStyles } from '../../Styles';
import ChangeAppointmentDialog from './ChangeAppointmentDialog';

function NextAppointment({
  closedAt,
  episodeId,
  state,
  language,
  fullname,
  email,
  scheduleURL,
  appointmentType,
  shouldPollNextAppointment = false,
}) {
  const history = useHistory();
  const classes = useAppStyles();
  const [hasNextAppointment, setHasNextAppointment] = useState(false);
  const [hasAppointmentLocation, setHasAppointmentLocation] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [eventUUID, setEventUUID] = useState('');
  const urlPostfix = `?hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1`;
  const [rescheduleURL, setRescheduleURL] = useState('');
  const [pollNextAppointment, setPollNextAppointment] = useState(shouldPollNextAppointment);
  const [cancelApptSuccess, setCancelApptSuccess] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog = cancelSuccess => {
    setDialogOpen(false);
    if (cancelSuccess) {
      setCancelApptSuccess(true);
      setHasNextAppointment(false);
      setHasAppointmentLocation(false);
      setPollNextAppointment(true);
    }
  };
  const handleScheduleAppointment = () => {
    history.push({
      pathname: `/schedule/${episodeId}`,
      state: {
        scheduleURL: scheduleURL,
        appointmentType: appointmentType,
      },
    });
  };

  const NEXT_APPOINTMENT = gql`
    query getNextUserAppointment($episodeId: ID!) {
      getNextUserAppointment(episodeID: $episodeId) {
        startTime
        location {
          joinUrl
        }
        event
        eventID
        rescheduleURL
      }
    }
  `;

  const {
    data: nextAppointmentData,
    nextAppointmentLoading,
    nextAppointmentError,
    // refetch: refetchNextAppointmentData,
  } = useQuery(NEXT_APPOINTMENT, {
    variables: { episodeId },
    pollInterval: pollNextAppointment ? 10000 : 0,
    skip: closedAt !== null, // skip check for next appointment if episode is closed
  });

  // TODO: investigate this further as this calls refetchNextAppointmentData on every render cycle
  // useEffect(() => {
  //   console.log('useEffect: calling refetchNextAppointmentData()');
  //   console.log('rescheduleURL', rescheduleURL);
  //   refetchNextAppointmentData();
  // }, [refetchNextAppointmentData]);

  useEffect(() => {
    if (nextAppointmentLoading) {
      // progress bar
    }
    if (nextAppointmentData) {
      if (!nextAppointmentData.getNextUserAppointment) {
        if (cancelApptSuccess) {
          setPollNextAppointment(false);
          setHasNextAppointment(false);
          setHasAppointmentLocation(false);
        }
      }
    }

    if (nextAppointmentData && nextAppointmentData.getNextUserAppointment) {
      setHasNextAppointment(true);

      if (nextAppointmentData.getNextUserAppointment.rescheduleURL) {
        setRescheduleURL(nextAppointmentData.getNextUserAppointment.rescheduleURL + urlPostfix);
      }

      if (nextAppointmentData.getNextUserAppointment.location?.joinUrl) {
        setHasAppointmentLocation(true);
      }

      setEventUUID(nextAppointmentData.getNextUserAppointment.eventID);

      // if (shouldPollNextAppointment) {
      //   console.log("next appt: should poll appointment: current is : " + JSON.stringify(nextAppointmentData.getNextAppointment))
      // }
    }
    if (nextAppointmentError) {
      return `an error occured: ${nextAppointmentError.message}`;
    }
  }, [nextAppointmentLoading, nextAppointmentData, nextAppointmentError, cancelApptSuccess, shouldPollNextAppointment, urlPostfix]);

  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  if (closedAt) {
    return <></>; // no need to check for next appointment if episode is closed
  }

  let formattedAppointment = '';
  if (hasNextAppointment && nextAppointmentData?.getNextUserAppointment?.startTime) {
    const apptDateTime = new Date(nextAppointmentData.getNextUserAppointment.startTime);
    formattedAppointment = apptDateTime.toLocaleDateString('en-US', options);
  } else {
    formattedAppointment = 'No upcoming appointment';
  }
  let joinUrl = '';
  if (hasAppointmentLocation) {
    joinUrl = nextAppointmentData.getNextUserAppointment.location.joinUrl.toString();
  }

  return (
    <>
      <Box className={classes.divider} sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Divider variant="middle" />
      </Box>
      <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline}>
        NEXT APPOINTMENT
      </Typography>
      {hasNextAppointment && (
        <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline} fontWeight={700}>
          {formattedAppointment}
        </Typography>
      )}
      <div>
        {!hasNextAppointment && (
          <Typography variant="subtitle1" display="block" gutterBottom className={classes.overline} fontWeight={700}>
            This episode does not have an upcoming appointment.
          </Typography>
        )}
        {!hasNextAppointment && (
          <Button
            style={{ backgroundColor: '#5b92e5' }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleScheduleAppointment}
            rel="noreferrer"
          >
            Schedule appointment
          </Button>
        )}
        {hasNextAppointment && hasAppointmentLocation && (
          <Button
            style={{ backgroundColor: defaultGreen }}
            variant="contained"
            size="medium"
            color="primary"
            href={joinUrl}
            target="_blank"
            rel="noreferrer"
          >
            Join appointment
          </Button>
        )}
      </div>
      <div className={classes.buttonContainer}>
        {hasNextAppointment && (
          <div>
            <Button
              style={{ backgroundColor: defaultBlue }}
              variant="contained"
              size="medium"
              color="primary"
              rel="noreferrer"
              onClick={handleOpenDialog}
            >
              Change appointment
            </Button>
            <ChangeAppointmentDialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              episodeId={episodeId}
              eventUUID={eventUUID}
              rescheduleURL={rescheduleURL}
              appointmentType={appointmentType}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(NextAppointment);
